import React, { useState, useEffect } from 'react'
import './index.css'
import {
    Box,
    Breadcrumbs,
    Grid,
    Link,
    Typography
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'

import * as ROUTES from '../../constants/routes'

import { withFirebase } from '../Firebase'
import { withAuthorization, AuthUserContext } from '../Session';
import { compose } from 'recompose';

const AppCMSBase = (props) => {
    const pushRoute = (destination) => {
        props.history.push(destination)
    }

    return(
        <Box m={5}>
            <Grid container direction="column" justify='center' alignItems='center' spacing={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" style={{cursor: 'pointer'}} onClick={() => {pushRoute(ROUTES.HOME)}}>Home</Link>
                    <Typography color="textPrimary">App CMS</Typography>
                </Breadcrumbs>

                <Grid item xs={12}>
                    <Typography variant="h2">App CMS</Typography>
                </Grid>

                <NewExhibitForm firebase={props.firebase}/>

            </Grid>
        </Box>
    )
}

const NewExhibitForm = ({firebase}) => {
    const newImage = {
        fileName: 'placeholder.png',
        description: '',
    }

    const [galleryImages, setGalleryImages] = useState([newImage])
    const [formData, setFormData] = useState({
        title: '',
        subtitle: '',
        heroImage: '/images/placeholder.png',
        teaserText: '',
        fullText: '',
        audioFile: 'placeholder',
        tlasQ1: '',
        tlasA1: '',
        tlasQ2: '',
        tlasA2: '',
        tlasQ3: '',
        tlasA3: '',
    })

    const addNewImage = () => {
        setGalleryImages(galleryImages.concat(newImage))
    }

    const removeImage = (index) => {
        // Only allow gallery card removal if there is more than one
        if(galleryImages.length > 1) {
            // Must create a new array and replace for React to acknowledge change and re-render
            const newArray = [...galleryImages]
            newArray.splice(index, 1)
            setGalleryImages(newArray)

        }
    }

    const handleImageDescriptionChange = (event, index) => {
        // Must create a new array and replace for React to acknowledge change and re-render
        const newArray = [...galleryImages]
        newArray[index].description = event.target.value
        setGalleryImages(newArray)
    }

    const updateField = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    return(
        <div>
            <h2>New Exhibit</h2>
            <Form>
                <HeroImage imageUrl={formData.heroImage}/>
                
                <Form.Group>
                    <Form.Label>Exhibit Title</Form.Label>
                    <Form.Control id="title" type="text" placeholder="ex: The Mastodons" value={formData.title} name="title" onChange={updateField}/>
                    <Form.Text className="text-light">This will be used to refer to the exhibit throughout the app.</Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Exhibit Subtitle</Form.Label>
                    <Form.Control id="subtitle" type="text" placeholder="ex: Ancient Herbivores" value={formData.subtitle} name="subtitle" onChange={updateField} />
                    <Form.Text className="text-light">A short catchy descriptor.</Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Teaser Text</Form.Label>
                    <Form.Control id="teaserText" as="textarea" rows={3} value={formData.teaserText} name="teaserText" onChange={updateField}/>
                    <Form.Text className="text-light">{`Short 2-3 sentence paragraph to intrigue the reader. Surround any text to italicize with <i> and </i> like: <i>Italicize this please</i>`}</Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Full Text</Form.Label>
                    <Form.Control id="fullText" as="textarea" rows={5} value={formData.fullText} name="fullText" onChange={updateField}/>
                    <Form.Text className="text-light">{`Full audio tour text. Surround any text to italicize with <i> and </i> like: <i>Italicize this please</i>`}</Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Audio File</Form.Label>
                    <Form.File id="audioFile" />
                    <Form.Text className="text-light">Audio file of narration in mp3 format.</Form.Text>
                </Form.Group>

                <h4>Think Like a Scientist</h4>
                <h5>1</h5>
                <Form.Group>
                    <Form.Label>Question</Form.Label>
                    <Form.Control id="TLAS_Q1" type="text" value={formData.tlasQ1} name="tlasQ1" onChange={updateField}/>

                    <Form.Label>Answer</Form.Label>
                    <Form.Control id="TLAS_A1" type="text" value={formData.tlasA1} name="tlasA1" onChange={updateField}/>
                </Form.Group>

                <h5>2</h5>
                <Form.Group>
                    <Form.Label>Question</Form.Label>
                    <Form.Control id="TLAS_Q2" type="text" value={formData.tlasQ2} name="tlasQ2" onChange={updateField}/>

                    <Form.Label>Answer</Form.Label>
                    <Form.Control id="TLAS_A2" type="text" value={formData.tlasA2} name="tlasA2" onChange={updateField}/>
                </Form.Group>

                <h5>3</h5>
                <Form.Group>
                    <Form.Label>Question</Form.Label>
                    <Form.Control id="TLAS_Q3" type="text" value={formData.tlasQ3} name="tlasQ3" onChange={updateField}/>

                    <Form.Label>Answer</Form.Label>
                    <Form.Control id="TLAS_A3" type="text" value={formData.tlasA3} name="tlasA3" onChange={updateField}/>
                </Form.Group>


                <h4>Image Gallery</h4>
                <ImageGallery galleryImages={galleryImages} addNewImage={addNewImage} removeImage={removeImage} handleImageDescriptionChange={handleImageDescriptionChange} firebase={firebase}/>
            </Form>
        </div>
    )
}

const HeroImage = ({imageUrl}) => {
    return(
        <Card style={{marginBottom: '10px'}}>
            <Card.Body>
                <Card.Title>Hero Image</Card.Title>
                <Card.Img variant="top" src={imageUrl} style={{width: 480, height: 270, marginBottom: '10px'}}/>
                <Form.Group>
                    <Form.File id="heroImage" />
                    <Form.Text>Large image shown at top of exhibit page. 16:9 aspect ratio, preferably 1920x1080 or 1280x720.</Form.Text>
                    </Form.Group>
            </Card.Body>
        </Card>
    )
}

const ImageGallery = ({galleryImages, addNewImage, removeImage, handleImageDescriptionChange, firebase}) => {
    return(
        galleryImages.map((image, index) => {
            return(
                <ImageGalleryCard key={index} image={image} index={index} galleryImages={galleryImages} addNewImage={addNewImage} removeImage={removeImage} handleImageDescriptionChange={handleImageDescriptionChange} firebase={firebase}/>
            )
        })

    )
}

const ImageGalleryCard = ({image, index, galleryImages, addNewImage, removeImage, handleImageDescriptionChange, firebase}) => {
    const fileId = 'image' + (index + 1) + 'File'
    const descriptionId = 'image' + (index + 1) + 'Description'
    const baseImageUrl = '/App/images/'

    const [imageUrl, setImageUrl] = useState('/images/placeholder.png')
    const [fileName, setFileName] = useState('placeholder.png')

    const updateImageUrl = (fileName) => {
        firebase.storage.ref().child(baseImageUrl + fileName).getDownloadURL().then((url) => {
            setImageUrl(url)
        })
    }

    return (
        <Card key={index} style={{marginTop: '10px'}}>
                    <Card.Body>
                        <div style={{position: 'absolute', top:'5px', right:'5px'}}>
                            <IconButton aria-label="remove" color="secondary" onClick={() => removeImage(index)}>
                                <CancelIcon />
                            </IconButton>

                        </div>
                        <Card.Title>Image {index+1}</Card.Title>
                        <Card.Img variant="top" src={imageUrl} style={{width: 480, height: 270, marginBottom: '10px'}}/>
                        <Form.Group>
                            <Form.File id={fileId} />
                            <Form.Label>Image Description</Form.Label>
                            <Form.Control 
                                id={descriptionId} type="text" 
                                placeholder="Image description..." 
                                onChange={ (event) => handleImageDescriptionChange(event, index)}
                                value={image.description}
                            />
                            <Form.Text>{`Surround any text to italicize with <i> and </i> like: <i>Italicize this please</i>`}</Form.Text>
                        </Form.Group>
                    </Card.Body>

                    {/* Add Another Image Button if last in array */}
                    { index === (galleryImages.length - 1) &&
                        <div style={{left: '0px', right:'0px', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bottom: '-30px', position: 'absolute'}}>
                            <IconButton aria-label="add another image" onClick={() => addNewImage()}>
                                <AddCircleIcon style={{color: 'green', fontSize: 40, backgroundColor: 'white', borderRadius: '50%'}}/>
                            </IconButton>
                        </div>
                    }
                </Card>
    )
}

const EditExhibitForm = () => {
    return(
        <h2>Edit Exhibit</h2>
    )
}

const EditTourForm = () => {
    return(
        <h2>Edit Tour</h2>
    )
}

const condition = authUser => !!authUser;

const AppCMS = compose(
    withFirebase,
    withAuthorization(condition)
)(AppCMSBase)

export default AppCMS